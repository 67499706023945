import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1913c736"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_Operation = _resolveComponent("Operation")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_row, {
    justify: "center",
    class: "layheader"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        span: 16,
        lg: 16,
        xs: 24,
        sm: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { justify: "space-between" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 1,
                lg: 1,
                xs: 0,
                sm: 0
              }),
              _createVNode(_component_el_col, {
                class: "left",
                span: 7,
                lg: 7,
                xs: 8,
                sm: 8
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, {
                    justify: "start",
                    align: "top"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_image, {
                        class: "left-img",
                        src: require('@/assets' + _ctx.json.logo)
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.json && _ctx.json.menu)
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    span: 16,
                    lg: 16,
                    xs: 0
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, {
                        justify: "end",
                        align: "middle"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_menu, {
                            "default-active": _ctx.activeIndex,
                            class: "el-menu-demo",
                            mode: "horizontal",
                            ellipsis: false,
                            onSelect: _ctx.handleSelect
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.menu.list, (item, ind) => {
                                return (_openBlock(), _createBlock(_component_el_menu_item, {
                                  key: ind,
                                  index: item.path,
                                  route: item.path
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["index", "route"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["default-active", "onSelect"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.json && _ctx.json.menu.list)
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 1,
                    xs: 10
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, {
                        justify: "end",
                        align: "middle"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_dropdown, {
                            trigger: "click",
                            onCommand: _cache[0] || (_cache[0] = (command) => {
                  _ctx.handleCommand(command, _ctx.getindex);
                })
                          }, {
                            dropdown: _withCtx(() => [
                              _createVNode(_component_el_dropdown_menu, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.menu.list, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                      key: index,
                                      command: _ctx.composeValue(item, index)
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["command"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_1, [
                                _createVNode(_component_el_icon, {
                                  size: 24,
                                  color: "#409eff"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Operation)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}