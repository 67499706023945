<template>
    <el-row justify="center" class="footer-wrap">
      <el-col :span="16" :xs="24">
        <div class="footer-container">
          <!-- <el-row justify="space-around" align="middle" class="footer-module">
            <el-col v-for="(item, index) in json.list" :key="index" :span="8" :xs="13">
              <a class="el-row footer-a footer-nowrap" v-if="item.path && item.path.indexOf('http') >= 0" :href="(item.path)" target="_blank">
                <div v-if="item.name || item.icon" class="footer-name">
                  <el-row class="max-height footer-nowrap text-nowrap" align="middle">
                    <el-icon v-if="item.icon =='Location'" size="30px"><Location /></el-icon>
                    <el-icon v-if="item.icon =='Phone'" size="30px"><Phone /></el-icon>
                    <el-icon v-if="item.icon =='Message'" size="30px"><Message /></el-icon>
                    <el-icon v-if="item.icon =='Promotion'" size="30px"><Promotion /></el-icon>
                    <el-image v-if="item.icon =='police'" class="has-s-pd-right" :src="require('@/assets' + json.imgs.police)" />
                    <el-image v-if="item.icon =='police2'" class="has-s-pd-right" :src="require('@/assets' + json.imgs.police)" />
                  </el-row>
                </div>
                <div class="footer-value">
                  <div v-if="item.name">{{item.name}}</div>
                  <el-row align="middle">
                    <div v-if="typeof item.value == 'string'">{{item.value}}</div>
                    <div class="has-pd-right" v-else v-for="(obj, key) in item.value" :key="key">{{obj}}</div>
                  </el-row>
                </div>
              </a>
              <el-row v-else class="footer-nowrap" justify="start" @click="toLink(item.path)">
                <div v-if="item.name" class="footer-name">
                  <el-row class="max-height footer-nowrap text-nowrap" align="middle">
                    <el-icon v-if="item.icon =='Phone'" size="40px"><Phone /></el-icon>
                    <el-icon v-if="item.icon =='Message'" size="40px"><Message /></el-icon>
                    <el-icon v-if="item.icon =='Promotion'" size="40px"><Promotion /></el-icon>
                  </el-row>
                </div>
                <div class="footer-value">
                  <div>{{item.name}}</div>
                  <el-row align="middle">
                    <div v-if="typeof item.value == 'string'">{{item.value}}</div>
                    <div class="has-pd-right" v-else v-for="(obj, key) in item.value" :key="key">{{obj}}</div>
                  </el-row>
                </div>
              </el-row>
            </el-col>
          </el-row> -->
          <el-row justify="center" align="middle" class="footer-module">
            <el-col v-for="(item, index) in json.list_01" :key="index" :span="24">
              <el-row justify="center" align="middle" @click="toLink(item.path)">
                <div v-if="item.name" class="footer-name has-pad-bottom">
                  <el-row class="max-height footer-nowrap text-nowrap" align="middle">
                    <el-icon v-if="item.icon =='Location'" size="20px"><Location /></el-icon>
                    <div class="footer-value">{{item.name}}</div>
                  </el-row>
                </div>
                <div class="footer-value text-center has-pad-bottom">
                  <el-row align="middle">
                    <div v-if="typeof item.value == 'string'">{{item.value}}</div>
                    <div class="has-pd-right" v-else v-for="(obj, key) in item.value" :key="key">{{obj}}</div>
                  </el-row>
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row justify="center" align="middle" class="has-pad-top has-pad-bottom">
            <div v-for="(item, index) in json.list_02" :key="index">
              <a class="el-row footer-a footer-nowrap f-align-center" v-if="item.path && item.path.indexOf('http') >= 0" :href="(item.path)" target="_blank">
                <div v-if="item.name || item.icon" class="footer-name">
                  <el-row class="max-height footer-nowrap text-nowrap" align="middle">
                    <el-image v-if="item.icon =='police'" class="has-s-pd-right" :src="require('@/assets' + json.imgs.police)" />
                    <el-image v-if="item.icon =='police2'" class="has-s-pd-right" :src="require('@/assets' + json.imgs.police)" />
                  </el-row>
                </div>
                <div class="footer-value">
                  <div v-if="item.name">{{item.name}}</div>
                  <el-row align="middle">
                    <div v-if="typeof item.value == 'string'">{{item.value}}</div>
                    <div class="has-pd-right" v-else v-for="(obj, key) in item.value" :key="key">{{obj}}</div>
                  </el-row>
                </div>
              </a>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>
</template>

<script lang="ts">
// import { AnyColumn } from 'element-plus/es/components/table-v2/src/common'
// import 'element-plus/theme-chalk/display.css'
import { Options, Vue } from 'vue-class-component'
import { goUrl } from '@/utils/index'
import { mapState } from 'vuex'

@Options({
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.footer,
      language: (state:any) => state.language
    })
  },
  mounted () {
    // console.log('footer')
  },
  methods: {
    toLink (path:any) {
      console.log(path)
      if (path && path !== '/') {
        goUrl(path)
      }
    }
  }
})
export default class Footer extends Vue {}
</script>
<style lang="scss" scoped>
  .el-footer{
    height: auto;
    padding: 0;
    margin: 0;
  }
  .footer-wrap{
    // min-height: 200px;
    height: auto;
    background: #222;
    color: #fff;
  }
  .footer-container{
    // height: 100%;
    width: 100%;
  }
  .footer-module{
    padding-top: 20px;
  }
  .has-pad-top{
    padding-top: 10px;
  }

  .has-pad-bottom{
    padding-bottom: 10px;
  }

  .el-image{
    display: block;
  }
  .img-left{
    max-width: 200px;
    // height: 100%;
  }
  .img-right{
    max-width: 200px;
    // height: 100%;
  }
  .max-height{
    height: 100%;
  }
  .min-height{
    min-height: 40px;
  }
  .footer-content{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-icon{
    margin-right: 10px;
  }
  .footer-name{
    // color: #00796a;
  }
  .footer-value{
    color: var(--el-color-info-light-3);
    font-size: 0.18rem;
  }
  .has-pd-right{
    padding-right: 20px;
  }
  .has-s-pd-right{
    padding-right: 10px;
  }
  .footer-a{
    border: 0;
    text-decoration: none;
  }
  .footer-nowrap{
    flex-wrap: nowrap;
  }
  .text-nowrap{
    white-space: nowrap;
  }
  .footer-police:first-child{
    margin-right: 20px;
  }
</style>
