<template>
  <div class="home">
    <template  v-if="info_info && info_info.logo">
      <el-image class="info-banner" :src="require('@/assets/imgs' + info_info.logo)" />
    </template>
    <template  v-else>
      <el-image class="info-banner" :src="require('@/assets/imgs' + json.logo)" />
    </template>
    <!-- <el-image class="info-banner" :src="require('@/assets/imgs' + json.logo)" /> -->
    <el-row justify="center">
      <el-col :span="16" :xs="22">
        <div class="info-container">
          <h1 class="text-center">{{info_info.name}}</h1>
          <div class="info-content" v-if="info_info.values">
            <div class="info-module" v-for="(item, index) in info_info.values" :key="index">
              <el-row
                 v-if="item.type == 'h1' || 'h2' || 'h3' || 'p' || 'img' || 'img_assets'"
                :justify="item.justify || 'start'"
                :align="item.align || 'top'"
              >
                <el-col :span="item.span || 24" :xs="24">
                  <!-- 各种type，组成不同的段落 -->
                  <h1 v-if="item.type == 'h1'" :class="item.class">{{item.value}}</h1>
                  <h2 v-if="item.type == 'h2'" :class="item.class">{{item.value}}</h2>
                  <h3 v-if="item.type == 'h3'" :class="item.class">{{item.value}}</h3>
                  <p v-if="item.type == 'p'" :class="item.class">{{item.value}}</p>
                  <!-- img 图片 -->
                  <el-image v-if="item.type == 'img'" :src="imgUrl + item.value" :class="item.class" />
                  <el-image v-if="item.type == 'img_assets'" :src="require('@/assets' + item.value)" :class="item.class"/>
                </el-col>
              </el-row>

              <!-- flex 布局，一行里有不同内容 -->
              <el-row class="info-flex" v-if="item.type == 'flex'" :justify="item.justify || 'start'" :align="item.align || 'top'" :class="item.class">
                <template v-for="(data, key) in item.value" :key="key">
                  <el-col :span="data.span || 10" :xs="24">
                    <p v-if="data.type == 'p'">{{data.value}}</p>
                    <el-image v-if="data.type == 'img'" :src="imgUrl + data.value" />
                    <el-image v-if="data.type == 'img_assets'" :src="require('@/assets' + data.value)" :class="item.class"/>
                  </el-col>
                </template>
              </el-row>

              <!-- br 换行 -->
              <br v-if="item.type == 'br'"/>
              <!-- divider 分割线 -->
              <el-divider  v-if="item.type == 'divider'"/>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <LayFooter/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'
import LayFooter from '@/components/layout/layfooter.vue'

// 使用接口获取数据

@Options({
  data () {
    return {
      activeIndex: '',
      list_id: '',
      info_id: '',
      lists_obj: {},
      info_obj: {},

      page_size: 9,
      activePage: 1,
      products_arr: [],
      info_info: {},
      menu_name: ''
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.info,
      language: (state:any) => state.language
    })
  },
  components: {
    LayFooter
  },
  updated () {
    // console.log('========= updated =========')
    this.infoInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.infoInit()
    // console.log('========= end =========')
  },
  methods: {
    infoInit () {
      const route = useRoute()
      // console.log('infoInit route ====', route)
      // console.log('infoInit route.params ====', route.params)
      // console.log('infoInit route.fullPath ====', route.fullPath)
      // console.log('infoInit route.query ====', route.query)
      // console.log('infoInit this.json ====', this.json)
      // console.log('infoInit this.json.list_obj ====', this.json.list_obj)
      if (route.params.infoid && route.params.infoid !== this.activeIndex) {
        // this.list_id = route.params.listid
        // this.activePage = route.params.page
        this.activeIndex = route.params.infoid
        this.info_id = route.params.infoid
        this.list_obj = this.json.list_obj || {}
        if (this.list_obj[this.info_id]) {
          this.info_info = this.list_obj[this.info_id]
        } else {
          goUrl('/404')
        }
      }
      // console.log('infoInit this.info_info ====', this.info_info)
    },
    goBack () {
      // console.log('========= goBack =========')
      this.$router.back()
      // console.log('========= end =========')
    }
  }
})
export default class Info extends Vue {}
</script>
<style lang="scss" scoped>
.info-banner{
  width: 100%;
  height: 4rem;
}
.info-container{
  padding: 0 0 120px;

  .info-content{
    .info-module{
      .text-center{
        text-align: center;
      }
      p{
        text-indent: 2em;
        line-height: 1.8em;
      }
    }
  }
}

.text-center{
  text-align: center;
}

.info-flex{
  margin: 18px 0;
}

</style>
