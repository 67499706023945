import { createStore } from 'vuex'
import header from './modules/header' // 引入modules的方法;
import getters from './getters' // 引入getters
// import { setLanguageStorage, getLanguageStorage } from '@/utils/storage'
import { setLanguageStorage } from '@/utils/storage'
import urlconfig from '@/utils/urlconfig'

// 获取所有json
function getJson () {
  // 不同语言
  const modulesFilesCn = require.context('@/assets/json/cn', true, /\.json$/)
  const cn = getJsonObj(modulesFilesCn)

  // const modulesFilesAr = require.context('@/assets/json/ar', true, /\.json$/)
  // const ar = getJsonObj(modulesFilesAr)
  // console.log('ar ======', ar)
  // console.log('cn ======', cn)
  return {
    // ar: ar,
    cn: cn
  }
}
function getJsonObj (modulesFiles:any) {
  const modules = modulesFiles.keys().reduce((modules:any, modulePath:any) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value
    return modules
  }, {})
  return modules
}

export default createStore({
  state: {
    // 图片域名
    imgUrl: urlconfig.imgUrl,
    // 默认中文的
    language: 'cn',
    jsons: {},
    cn: {},
    ar: {},
    loaded: false
  },
  mutations: {
    SET_All_JSON: (state:any, obj:any) => {
      // console.log('SET_All_JSON state =====', state)
      // console.log('SET_All_JSON state.jsons =====', state.jsons)
      // console.log('SET_All_JSON obj =====', obj)
      // 不同语言
      state.cn = obj.cn
      // state.ar = obj.ar
      // json 内容赋值
      state.jsons = obj[state.language]
      // 是否已加载
      state.loaded = true
    },
    CHANGE_LANGUAGE: (state:any, language:string) => {
      state.language = language
    },
    CHANGE_JSON: (state:any, language:string) => {
      // console.log('CHANGE_JSON state =====', state)
      // console.log('CHANGE_JSON state.jsons =====', state.jsons)
      // console.log('CHANGE_JSON language =====', language)
      state.language = language
      state.jsons = state[language]
    }
  },
  actions: {
    // 切换语言
    changeJson ({ commit, state }:any, language:string) {
      // return new Promise((resolve, reject) => {
      return new Promise((resolve) => {
        // console.log('Promise')
        if (language && state.language !== language) {
          // console.log('换语言')
          setLanguageStorage(language)
          commit('CHANGE_JSON', language)
          resolve('success')
        } else {
          resolve('do not change')
        }
      })
    },
    // 获取所有json
    getAllJson ({ commit, state }:any) {
      // return new Promise((resolve, reject) => {
      return new Promise(() => {
        /*
          // 多语言
          const oldLanguage = getLanguageStorage()
          // console.log('oldLanguage ====', oldLanguage)
          // console.log('state ====', state)
          if (oldLanguage && oldLanguage !== state.language) {
            commit('CHANGE_LANGUAGE', oldLanguage)
          }
          if (!oldLanguage) {
            setLanguageStorage(state.language)
          }
        */
        if (!state.loaded) {
          const jsonObj = getJson()
          // console.log('jsonObj =====', jsonObj)

          commit('SET_All_JSON', jsonObj)
        }
      })
    }
  },
  modules: {
    header
  },
  getters
})

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// })
