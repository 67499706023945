<template>
  <div class="introduce-wrap">
    <el-row justify="center">
      <el-col :span="16" :xs="22">
        <div class="introduce-container">
          <h3>公司介绍</h3>
          <el-row class="introduction-content" justify="space-between">
            <el-col :span="12" :xs="24">
              <p class="no-margin-top">郑州比特率信息科技有限公司坐落于河南省郑州市。公司秉承”在磨砺中成长丶在传承中发展丶在感恩中前行”的理念。发挥自身特长,专注于软件定制开发和技术服务。</p>
              <p>团队核心人员均为曾在北上广深从事多年的软件研发工作，拥有强大的技术实力和前瞻性，致力于打造出位于中原地带的高新技术科技公司，为还在外地打拼的莘莘学子创造出一个拥有现代化开发技术和归属感的平台。</p>
              <p>凭借人力资源的优势，郑州比特率信息科技有限公司能够始终站在技术前沿，为客户提供优质的服务和产品，希望我们的技术也能驱动每一位客户业务的迅速发展。</p>
            </el-col>
            <el-col :span="11" :xs="24">
              <el-image fit='scale-down' :src="require('@/assets/imgs/introduce.jpg')"/>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <LayFooter/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
// import { useRoute } from 'vue-router'
import LayFooter from '@/components/layout/layfooter.vue'

// 使用接口获取数据

@Options({
  data () {
    return {
      activeIndex: '',
      list_id: '',
      info_id: '',
      lists_obj: {},
      info_obj: {},

      page_size: 9,
      activePage: 1,
      products_arr: [],
      info_info: {},
      menu_name: ''
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      language: (state:any) => state.language
    })
  },
  components: {
    LayFooter
  },
  updated () {
    // console.log('========= updated =========')
    this.introduceInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.introduceInit()
    // console.log('========= end =========')
  },
  methods: {
    introduceInit () {
      // const route = useRoute()
      // console.log('introduceInit route ====', route)
      // console.log('introduceInit route.params ====', route.params)
      // console.log('introduceInit route.fullPath ====', route.fullPath)
      // console.log('introduceInit route.query ====', route.query)
      // console.log('introduceInit this.json ====', this.json)
      // console.log('introduceInit this.json.list_obj ====', this.json.list_obj)
    },
    goBack () {
      // console.log('========= goBack =========')
      this.$router.back()
      // console.log('========= end =========')
    }
  }
})
export default class Introduce extends Vue {}
</script>
<style lang="scss" scoped>
.introduce-wrap{
  min-height: 100%;
}
.introduce-container{
  width: 100%;
  padding: 50px 0 250px;
  p {
    text-indent: 36px;
  }
}
.introduce-content{
}
.no-margin-top{
  margin-top: 0;
}
</style>
