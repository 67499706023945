<template>
  <div class="office-wrap">
    <vue-office-pdf
      :src="pdf"
      @rendered="renderingCompleted"
      @error="errorHandler"
    />
    <div v-if="error_text">
      {{ error_text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import VueOfficePdf from '@vue-office/pdf'
import { ElLoading } from 'element-plus'

@Options({
  data () {
    return {
      // pdf: 'https://bitrate.oss-cn-zhangjiakou.aliyuncs.com/GunsV7%E8%A7%86%E9%A2%91%E9%85%8D%E5%A5%97%E6%96%87%E6%A1%A3v1.1.pdf'
      pdf: '',
      loading: null,
      error_text: null
    }
  },
  components: {
    VueOfficePdf
  },
  mounted () {
    console.log('===== mounted =====')
    const route = useRoute()
    console.log('office route ====', route)
    if (route.query.url) {
      // console.log('office url ====', route.query.url)
      this.pdf = route.query.url
      this.showLoading()
    }
  },
  methods: {
    showLoading () {
      this.loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeLoading () {
      if (this.loading) {
        this.loading.close()
        this.loading = null
      }
    },
    renderingCompleted () {
      console.log('渲染完成')
      this.closeLoading()
    },
    errorHandler (e:any) {
      console.log('errorHandler e ===', e)
      this.error_text = e
      this.closeLoading()
    }
  }
})
export default class Pdf extends Vue {}
</script>
<style lang="scss" scoped>
.office-wrap{
  width: 100%;
}
</style>
