<template>
  <div class="about">
    <el-row justify="center">
      <el-col :span="16" :xs="22">
        <el-row align="middle">
          <el-col :span="4" :xs="0">
          </el-col>
          <el-col :span="4" :xs="24">
            <el-row align="middle" justify="center">
              <el-image class="about-left-img" :src="require('@/assets' + json.info.logo)" />
            </el-row>
          </el-col>
          <el-col :span="1" :xs="0">
          </el-col>
          <el-col :span="15" :xs="24" class="about-infos">
            <h3 class="text-red" style="font-size: 24px;">{{json.info.name}}</h3>
            <div v-for="(item, index) in json.info.values" :key="index" :dir="language=='ar' ? 'rtl' : 'ltr'">
              {{item}}
            </div>
          </el-col>
        </el-row>
        <el-row justify="center" class="line">
          <el-image :src="require('@/assets' + json.info.line)" />
        </el-row>
        <el-row justify="center" class="about-wrap">
          <div id="container" style="width: 800px;height: 400px"></div>
        </el-row>
      </el-col>
    </el-row>
    <LayFooter/>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import LayFooter from '@/components/layout/layfooter.vue'

let BMap:any = {}

@Options({
  data () {
    return {
    }
  },
  components: {
    LayFooter
  },
  methods: {
    createMap () {
      // console.log(window)
      BMap = (window as any).BMapGL
      // console.log('BMap ====', BMap)
      console.log('json ====', this.json)
      this.$nextTick(() => {
        const mapInfo = this.json.map
        const map = new BMap.Map('container')
        const point = new BMap.Point(mapInfo.coordinateX, mapInfo.coordinateY)
        map.centerAndZoom(point, mapInfo.level)
        map.enableScrollWheelZoom(true)
        const marker = new BMap.Marker(point)
        marker.setTitle('郑州德原电力')
        map.addOverlay(marker)
      })
    }
  },
  mounted () {
    console.log('===== mounted =====')
    this.createMap()
    console.log(this.language)
    setTimeout(() => {
      console.log(this.language)
    }, 2 * 1000)
  },
  computed: {
    ...mapState({
      json: (state:any) => state.jsons.about,
      language: (state:any) => state.language
    })
  }
})
export default class About extends Vue {}
</script>
<style lang="scss" scoped>
  .about{
    background: #FAFAFA;
  }

.about-wrap{
  padding: 0 0 80px;
}
.about-infos h4, .about-infos p, .about-infos div{
  margin: 0.4em;
  font-size: 18px;
}
.text-red{
  color: #FF6602;
}

.text-green{
  color: #00796a;
}
.line{
  padding: 30px 0 30px;
}
.about-left-img{
  width: 100%;
  height: 100%;
}
</style>
