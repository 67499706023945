<template>
  <div class="office-wrap">
    <vue-office-docx
      :src="docx"
      @rendered="renderingCompleted"
      @error="errorHandler"
    />
    <div v-if="error_text">
      {{ error_text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import VueOfficeDocx from '@vue-office/docx'
// 引入相关样式
import '@vue-office/docx/lib/index.css'
import { ElLoading } from 'element-plus'

@Options({
  data () {
    return {
      // https://bitrate.oss-cn-zhangjiakou.aliyuncs.com/%E6%88%BF%E7%A7%9F%E7%A7%9F%E8%B5%81%E5%90%88%E5%90%8C.doc
      // https://bitrate.oss-cn-zhangjiakou.aliyuncs.com/%E8%BD%AF%E4%BB%B6%E9%A1%B9%E7%9B%AE%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B9%A6.docx
      docx: '',
      loading: null,
      error_text: null
    }
  },
  components: {
    VueOfficeDocx
  },
  mounted () {
    console.log('===== mounted =====')
    const route = useRoute()
    console.log('office route ====', route)
    if (route.query.url) {
      // console.log('office url ====', route.query.url)
      this.docx = route.query.url
      this.showLoading()
    }
  },
  methods: {
    showLoading () {
      this.loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeLoading () {
      if (this.loading) {
        this.loading.close()
        this.loading = null
      }
    },
    renderingCompleted () {
      console.log('渲染完成')
      this.closeLoading()
    },
    errorHandler (e:any) {
      console.log('errorHandler e ===', e)
      this.error_text = e
      this.closeLoading()
    }
  }
})
export default class Docx extends Vue {}
</script>
<style lang="scss" scoped>
.office-wrap{
  width: 100%;
}
</style>
