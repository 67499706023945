import Vrouter from '@/router'

export const goUrl = (opt:any) => {
  const router = Vrouter
  // const url = opt.url ? opt.url : opt
  let obj:any = {
    path: opt.url ? opt.url : opt
  }
  if (typeof opt === 'string') {
    // console.log('typeof opt === "string"')
    if (opt.indexOf('?') >= 0) {
      // console.log('opt.indexOf("?") =====', opt.indexOf('?'))
      const query = getParams(opt)
      // console.log('query =====', query)
      obj = query
    } else {
      obj.path = opt
    }
  }
  if (opt.query) {
    obj.query = opt.query
    obj.path = opt.url
  }
  router.push(obj).then(() => {
    // console.log('router.push then ===')
    // opt.callback && opt.callback()
  })
}
function getParams (url:string) {
  // 获取参数
  const obj:any = {
    path: '',
    query: {}
  }
  if (url.indexOf('?') > 0) {
    obj.path = url.split('?')[0]
  }
  /*
  * 正则匹配
  * [?&]:匹配?或&其一
  * [^?&]:非?或非&
  * */
  const reg = /[?&][^?&]+=[^?&]+/g
  const arr = url.match(reg) // return ["?id=123456","&a=b"]
  if (arr) {
    arr.forEach((item:any) => {
      const tempArr = item.substring(1).split('=')
      const key = tempArr[0]
      const val = tempArr[1]
      obj.query[key] = val
    })
  }
  return obj
}
