import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../components/layout/index.vue'
import Home from '../views/home.vue'
import NotFound from '../views/404.vue'
import List from '../views/list.vue'
import Info from '../views/info.vue'
import About from '../views/about.vue'
import Introduce from '../views/introduce.vue'
import Docx from '../views/office/docx.vue'
import Excel from '../views/office/excel.vue'
import Pdf from '../views/office/pdf.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,

    // 重定向
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: Home,
        name: 'home',
        meta: {
          title: '首页 hello'
          // icon: "dashboard",
          // affix: true
        }
      }
    ]
  },
  {
    path: '/list',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/list/:listid',
        component: List,
        name: 'list',
        meta: {
          title: '列表页'
        }
      }
    ]
  },
  {
    path: '/info',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        // path: '/info/:listid/:infoid',
        path: '/info/:infoid',
        component: Info,
        name: 'info',
        meta: {
          title: '详情页'
        }
      }
    ]
  },
  {
    path: '/about',
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: Layout,
    children: [
      {
        path: '/about',
        component: About,
        name: 'about',
        meta: {
          title: '关于'
        }
      }
    ]
  },
  {
    path: '/introduce',
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: Layout,
    children: [
      {
        path: '/introduce',
        component: Introduce,
        name: 'introduce',
        meta: {
          title: '公司介绍'
        }
      }
    ]
  },
  {
    path: '/office/docx',
    component: Docx,
    name: 'docx'
  },
  {
    path: '/office/excel',
    component: Excel,
    name: 'excel'
  },
  {
    path: '/office/pdf',
    component: Pdf,
    name: 'pdf'
  },
  {
    path: '/:pathMatch(.*)',
    component: Layout,
    redirect: '/404',
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/404',
        component: NotFound,
        name: 'notfound',
        meta: {
          title: '无当前页'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
