<template>
  <div class="rightnav-wrap">
    <el-row
      class="nav-module" align="middle" justify="center"
      v-for="(item, index) in json.list" :key="index"
    >
      <!-- 置顶 -->
      <template v-if="item.type == 'top'">
        <el-button class="nav-btn" type="primary" @click="toTop">
          <el-icon><Top /></el-icon>
        </el-button>
      </template>
      <!-- 各个展示小模块 -->
      <template v-if="item.type == 'pop'">
        <!-- 触碰弹出框展示 -->
        <el-popover
          placement="left"
          :title="item.name"
          :width="200"
          trigger="hover"
        >
          <!-- 原内容 -->
          <template #reference>
            <el-button class="nav-btn" type="primary">
              <el-icon v-if="item.icon == 'ChatDotRound'"><ChatDotRound /></el-icon>
              <el-icon v-if="item.icon == 'Phone'"><Phone /></el-icon>
              <el-icon v-if="item.icon == 'Message'"><Message /></el-icon>
            </el-button>
          </template>
          <!-- 弹出框内容 -->
          <div>
            <el-image v-if="item.value_type == 'img'"
              :src="require('@/assets' + item.value)"
            />
            <span v-if="item.value_type == 'text'" @click="copy(item.value)">
              {{item.value}}
            </span>
          </div>
        </el-popover>
      </template>
    </el-row>

  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()

@Options({
  data () {
    return {
      isTop: false
    }
  },
  computed: {
    ...mapState({
      json: (state:any) => state.jsons.rightnav
    })
  },
  components: {
  },
  mounted () {
    // console.log(123)
  },
  methods: {
    toTop () {
      document.documentElement.scrollTop = 0
    },
    copy (text:any) {
      console.log('copy text ===', text)
      toClipboard(text).then(res => {
        console.log('res ===', res)
        ElMessage({
          type: 'success',
          message: '已复制点击内容！'
        })
      })
    }
  }
})
export default class Header extends Vue {}
</script>
<style lang="scss" scoped>
.rightnav-wrap{
  position: fixed;
  top: 70%;
  right: 20px;
  transform: translateY(-50%);
}
.nav-module{
  .nav-btn{
    width: 50px;
    height: 50px;
    font-size: 30px;
    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
      font-size: 24px;
    }
  }
  &:not(:last-child){
    margin-bottom: 2px;
  }
}

</style>
