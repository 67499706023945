import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe23f67a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "size-28 has_pad_bottom has_pad_top" }
const _hoisted_3 = { class: "size-16" }
const _hoisted_4 = { class: "module_img_wrap" }
const _hoisted_5 = { class: "module_bottom flex f-align-center f-justify-center" }
const _hoisted_6 = { class: "introduce-container" }
const _hoisted_7 = { class: "no-margin-top" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "size-28 has_pad_bottom has_pad_top" }
const _hoisted_10 = { class: "size-16" }
const _hoisted_11 = { class: "module_img_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_pageWrap = _resolveComponent("pageWrap")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_LayFooter = _resolveComponent("LayFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.list, (aItem, ind) => {
      return (_openBlock(), _createElementBlock("div", { key: ind }, [
        (aItem.type === 'banner' && aItem.list_type === 'banner')
          ? (_openBlock(), _createBlock(_component_el_carousel, {
              key: 0,
              autoplay: false,
              height: "5rem",
              class: "banner-carousel"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aItem.list, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_image, {
                        class: "full-img",
                        fit: "cover",
                        src: require('@/assets' + item.img),
                        onDragstart: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (aItem.type === 'img')
          ? (_openBlock(), _createBlock(_component_pageWrap, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, {
                  class: "full-img",
                  fit: "contain",
                  src: require('@/assets' + aItem.img),
                  onDragstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (aItem.type === 'list' && aItem.list_type === 'img_name' && false)
          ? (_openBlock(), _createBlock(_component_pageWrap, {
              key: 2,
              class: "has_100_pad_bottom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(aItem.title), 1),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(aItem.subtitle), 1)
                ]),
                _createVNode(_component_el_row, {
                  justify: "space-evenly",
                  class: "container-module"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aItem.list, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_col, {
                        span: 8,
                        xs: 7,
                        key: index,
                        class: "img_card_module",
                        onClick: ($event: any) => (_ctx.toLink(item.path))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_card, {
                            class: "module_content",
                            "body-style": { padding: '10px' }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_el_image, {
                                  class: "module_img",
                                  fit: "scale-down",
                                  src: require('@/assets' + item.img),
                                  onDragstart: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                                }, null, 8, ["src"])
                              ]),
                              _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (aItem.type === 'text' && aItem.list_type === 'introduce')
          ? (_openBlock(), _createBlock(_component_pageWrap, {
              key: 3,
              class: "has_100_pad_bottom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_row, {
                    class: "introduce-content size-18",
                    justify: "center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 12,
                        xs: 24
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", _hoisted_7, _toDisplayString(aItem.title), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aItem.values, (item, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (item.type == 'text')
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: _normalizeClass(item.class)
                                  }, _toDisplayString(item.text), 3))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, {
                        span: 1,
                        xs: 0
                      }),
                      _createVNode(_component_el_col, {
                        span: 11,
                        xs: 24
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_image, {
                            fit: "scale-down",
                            src: require('@/assets/imgs/introduce.jpg')
                          }, null, 8, ["src"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (aItem.type === 'list' && aItem.list_type === 'img' && false)
          ? (_openBlock(), _createBlock(_component_pageWrap, {
              key: 4,
              class: "has_100_pad_bottom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(aItem.title), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(aItem.subtitle), 1)
                ]),
                _createVNode(_component_el_row, {
                  justify: "space-evenly",
                  class: "container-module"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aItem.list, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_col, {
                        span: 8,
                        key: index,
                        class: "img_card_module",
                        onClick: ($event: any) => (_ctx.toLink(item.path))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_card, {
                            class: "module_content",
                            "body-style": { padding: '10px' }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_el_image, {
                                  class: "module_img",
                                  fit: "scale-down",
                                  src: require('@/assets' + item.img),
                                  onDragstart: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                                }, null, 8, ["src"])
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("div", null, [
      _createVNode(_component_el_row, {
        justify: "center",
        class: "has_pad_top size-28"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 公司地址 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, {
        justify: "center",
        class: "about-wrap has_150_pad_bottom"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            id: "homeMap",
            style: {"width":"800px","height":"400px","max-width":"90%"},
            onMousedown: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
          }, null, 32)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "home_footer",
        onMousedown: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_LayFooter)
      ], 32)
    ])
  ]))
}