<template>
  <div class="list-wrap">
    <template  v-if="list_obj && list_obj.logo">
      <el-image class="list-banner" :src="require('@/assets/imgs' + list_obj.logo)" />
    </template>
    <template  v-else>
      <el-image class="list-banner" :src="require('@/assets/imgs' + json.logo)" />
    </template>
    <el-row justify="center">
      <el-col :span="16" :xs="24">
        <el-row class="list-container">
          <el-col :span="24">
            <el-row class="list-content">
              <el-col :span="8" :xs="11" v-for="(item, index) in lists_arr" :key="index"  class="list-li" @click="goInfo(item)">
                <el-row justify="center">
                  <el-image :src="item.coverImage.indexOf('http') >= 0 ? item.coverImage: require('@/assets/imgs' + item.coverImage)" />
                </el-row>
                <el-row justify="center">
                  <div :dir="language=='ar' ? 'rtl' : 'ltr'">{{item.name}}</div>
                </el-row>
              </el-col>
            </el-row>
            <el-row justify="center" v-if="lists_arr && lists_arr.length > 0">
              <el-pagination
                class="info-pagination"
                small
                :background="false"
                layout="prev, pager, next"
                :page-size="page_size"
                :total="total * 1"
                :current-page="activePage"
                @current-change="handleCurrentChange"
              />
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <LayFooter/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'
import LayFooter from '@/components/layout/layfooter.vue'

// import {
//   get
// } from '@/service/ajax'

@Options({
  data () {
    return {
      activeIndex: '',
      page_size: 9,
      activePage: 1,

      listid: '',
      list_obj: {},

      list_menus: [],
      lists_arr: [],
      total: 1
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.list,
      language: (state:any) => state.language
    })
  },
  components: {
    LayFooter
  },
  updated () {
    // console.log('========= updated =========')
    this.listInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.listInit()
    // console.log('========= end =========')
  },
  methods: {
    // 初始化
    listInit () {
      const route = useRoute()
      // console.log('listInit route ====', route)
      // console.log('listInit route.params ====', route.params)
      // console.log('listInit route.fullPath ====', route.fullPath)
      // console.log('listInit route.query ====', route.query)
      // console.log('listInit this.json ====', this.json)
      // console.log('listInit lists_obj ====', this.json.lists_obj)

      this.activePage = 1
      this.activeIndex = route.params.listid
      this.listid = route.params.listid
      this.list_obj = this.json.lists_obj[this.listid]
      console.log('listInit lists_obj ====', this.list_obj)
      if (this.list_obj) {
        this.getList()
      } else {
        goUrl('/404')
      }
    },
    // 获取列表
    getList () {
      // console.log('listInit list_obj ====', this.list_obj)
      this.total = this.list_obj.list.length
      this.getShowList()
    },
    // 展示不同的列表
    getShowList () {
      // console.log('this.activePage ===', this.activePage)
      // console.log('this.page_size ===', this.page_size)
      // this.lists_arr = this.list_obj.list || []
      const list = this.list_obj.list || []
      const min = this.page_size * (this.activePage - 1)
      const max = this.page_size * this.activePage
      this.lists_arr = []
      list.forEach((item:any, index:number) => {
        // console.log('item ===', item)
        // console.log('index ===', index)
        if (index >= min && index < max) {
          this.lists_arr.push(item)
        }
      })
      // console.log('this.lists_arr ===', this.lists_arr)
    },
    // handleSelect (key: string, keyPath: string[], item:any) {
    handleSelect (key: string) {
      // console.log('========= handleSelect =========')
      // key === /list/2
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      // console.log('========= end =========')
      if (key !== this.activeIndex) {
        this.activeIndex = key
        goUrl(key)
      }
    },
    goInfo (activity:any) {
      // console.log('goInfo activity ====', activity)
      if (activity.id) {
        const url = '/info/' + activity.id
        goUrl(url)
      }
    },
    handleCurrentChange (val: number) {
      console.log(`current page: ${val}`)
      this.activePage = val
      this.getShowList()
    }
  }
})
export default class List extends Vue {}
</script>
<style lang="scss" scoped>
.list-banner{
  width: 100%;
  height: 4rem;
}
.list-container{
  padding: 30px 0 80px;
}
.list-infos-btn{
  cursor: pointer;
}
.list-infos-btn:hover{
  opacity: .7;
}
.list-infos-has-img{
  text-align: center;
}

.list-content{
  .list-li{
    padding: 0 30px 30px;
    .el-image{
      width: 100%;
      height: 170px;
      background: #fff;
      margin-bottom: 20px;
    }
  }
}
.text-center{
  text-align: center;
}

.info-pagination{
  background: transparent;
  button{
    background: transparent;
  }
}
</style>
