<template>
  <el-container class="home">
    <el-header height="0.9rem">
      <Layheader/>
    </el-header>
    <div class="kong"></div>
    <div class="has-pad">
      <router-view/>
    </div>
    <!-- <LayFooter/> -->
    <RightNav />
  </el-container>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Layheader from '@/components/layout/layheader.vue'
import LayFooter from '@/components/layout/layfooter.vue'
import RightNav from '@/components/layout/rightnav.vue'

@Options({
  components: {
    Layheader,
    LayFooter,
    RightNav
  },
  computed: {
    // hHeight () {
    //   console.log(document.documentElement.clientHeight)
    //   return document.documentElement.clientHeight
    // }
  },
  methods: {
    toTop () {
      // console.log(666)
      // console.log(this.$refs.main)
      // this.$refs.main.scrollIntoView(0)
    }
  }
})
export default class LayOut extends Vue {}
</script>

<style lang="scss">
@import '@/styles/index.scss';

html,body{
  height: 100%
}
#app{
  height: 100%;
}
.el-header,.el-main,.el-footer{
  padding: 0 !important;
}
.kong{
  height: 10px;
}
.home{
  height: 100%;
  // background: $color-black;
}
</style>
