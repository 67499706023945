<template>
  <div class="office-wrap">
    <vue-office-excel
      :src="excel"
      style="min-width: 1920px;"
      @rendered="renderingCompleted"
      @error="errorHandler"
    />
    <div v-if="error_text">
      {{ error_text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import VueOfficeExcel from '@vue-office/excel'
import { ElLoading } from 'element-plus'

@Options({
  data () {
    return {
      // excel: 'https://bitrate.oss-cn-zhangjiakou.aliyuncs.com/%E4%B8%BB%E8%A6%81%E5%8F%82%E4%B8%8E%E4%BA%BA%E5%91%98.xlsx'
      excel: '',
      loading: null,
      error_text: null
    }
  },
  components: {
    VueOfficeExcel
  },
  mounted () {
    console.log('===== mounted =====')
    const route = useRoute()
    console.log('office route ====', route)
    if (route.query.url) {
      // console.log('office url ====', route.query.url)
      this.excel = route.query.url
      this.showLoading()
    }
  },
  methods: {
    showLoading () {
      this.loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeLoading () {
      if (this.loading) {
        this.loading.close()
        this.loading = null
      }
    },
    renderingCompleted () {
      console.log('渲染完成')
      this.closeLoading()
    },
    errorHandler (e:any) {
      console.log('errorHandler e ===', e)
      this.error_text = e
      this.closeLoading()
    }
  }
})
export default class Excel extends Vue {}
</script>
<style lang="scss" scoped>
.office-wrap{
  width: 100%;
}
</style>
