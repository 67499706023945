import router from './index'
// 导入
// import NProgress from 'nprogress'
// import { getToken, setToken } from '@/utils/cookies';
// 白名单
// const whiteList = [
//   '/login'
// ]
router.beforeEach((to, from, next) => {
  // NProgress.start()
  // console.log('from ====', from)
  // console.log('to ====', to)
  // console.log( 'next ====', next )
  // console.log( 'getToken ====', getToken() )
  // if (getToken() || true) {
  //   // if (to.path === '/login') {
  //   //   next({
  //   //     path: '/'
  //   //   })
  //   // }else{
  //     next()
  //   // }
  // }else{
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next();
  //   } else {
  //     next('/login');
  //   }
  //     NProgress.done();
  // }
  next()
})
router.afterEach(() => {
  // console.log('==== afterEach ====')
  // NProgress.done()
})
