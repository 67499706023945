<template>
  <router-view v-if="isRouterAlive"/>
</template>
<script>
import { provide, ref, nextTick } from 'vue'
export default {
  setup () {
    // console.log('APP setup')
    const isRouterAlive = ref(true)
    const reload = () => {
      // console.log('APP reload')
      isRouterAlive.value = false
      nextTick(() => {
        isRouterAlive.value = true
      })
    }
    provide('reload', reload)
    return { isRouterAlive }
  }
}
</script>

<style lang="scss">
@import '@/styles/index.scss';

html,body{
  margin: 0;
  padding: 0;
  min-height: 100%;
}
.all-height{
  height: 100%;
}
image{
  display: block;
  margin: 0;
}
.out-hidden{
  // 超出一行省略号
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.out-hidden2{
  // 超出两行省略号
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>
