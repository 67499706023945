import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b669fbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "office-wrap" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_office_excel = _resolveComponent("vue-office-excel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_office_excel, {
      src: _ctx.excel,
      style: {"min-width":"1920px"},
      onRendered: _ctx.renderingCompleted,
      onError: _ctx.errorHandler
    }, null, 8, ["src", "onRendered", "onError"]),
    (_ctx.error_text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error_text), 1))
      : _createCommentVNode("", true)
  ]))
}