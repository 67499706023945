<template>
  <div class="no-message">
    <!-- <el-row justify="center"> -->
      <el-empty description="页面未找到" />
    <!-- </el-row> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
  },
  computed: {
  }
})
export default class NotFound extends Vue {}
</script>
<style lang="scss" scoped>
.no-message{
  padding: 160px 0;
}
</style>
