<template>
  <el-row justify="center">
    <el-col :span="16" :xs="22">
      <slot/>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
// 可展示区，页面中心
@Options({
  components: {
  },
  computed: {
  }
})
export default class LayOut extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
</style>
