import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6045143c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-container" }
const _hoisted_2 = {
  key: 0,
  class: "footer-name has-pad-bottom"
}
const _hoisted_3 = { class: "footer-value" }
const _hoisted_4 = { class: "footer-value text-center has-pad-bottom" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "footer-name"
}
const _hoisted_8 = { class: "footer-value" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Location = _resolveComponent("Location")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_row, {
    justify: "center",
    class: "footer-wrap"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        span: 16,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, {
              justify: "center",
              align: "middle",
              class: "footer-module"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.list_01, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_col, {
                    key: index,
                    span: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, {
                        justify: "center",
                        align: "middle",
                        onClick: ($event: any) => (_ctx.toLink(item.path))
                      }, {
                        default: _withCtx(() => [
                          (item.name)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_el_row, {
                                  class: "max-height footer-nowrap text-nowrap",
                                  align: "middle"
                                }, {
                                  default: _withCtx(() => [
                                    (item.icon =='Location')
                                      ? (_openBlock(), _createBlock(_component_el_icon, {
                                          key: 0,
                                          size: "20px"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_Location)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_el_row, { align: "middle" }, {
                              default: _withCtx(() => [
                                (typeof item.value == 'string')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.value), 1))
                                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.value, (obj, key) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "has-pd-right",
                                        key: key
                                      }, _toDisplayString(obj), 1))
                                    }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, {
              justify: "center",
              align: "middle",
              class: "has-pad-top has-pad-bottom"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.list_02, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    (item.path && item.path.indexOf('http') >= 0)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "el-row footer-a footer-nowrap f-align-center",
                          href: (item.path),
                          target: "_blank"
                        }, [
                          (item.name || item.icon)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_el_row, {
                                  class: "max-height footer-nowrap text-nowrap",
                                  align: "middle"
                                }, {
                                  default: _withCtx(() => [
                                    (item.icon =='police')
                                      ? (_openBlock(), _createBlock(_component_el_image, {
                                          key: 0,
                                          class: "has-s-pd-right",
                                          src: require('@/assets' + _ctx.json.imgs.police)
                                        }, null, 8, ["src"]))
                                      : _createCommentVNode("", true),
                                    (item.icon =='police2')
                                      ? (_openBlock(), _createBlock(_component_el_image, {
                                          key: 1,
                                          class: "has-s-pd-right",
                                          src: require('@/assets' + _ctx.json.imgs.police)
                                        }, null, 8, ["src"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_8, [
                            (item.name)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.name), 1))
                              : _createCommentVNode("", true),
                            _createVNode(_component_el_row, { align: "middle" }, {
                              default: _withCtx(() => [
                                (typeof item.value == 'string')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.value), 1))
                                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.value, (obj, key) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "has-pd-right",
                                        key: key
                                      }, _toDisplayString(obj), 1))
                                    }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ], 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}