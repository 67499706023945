<template>
    <el-row justify="center" class="layheader">
      <el-col :span="16" :lg="16" :xs="24" :sm="24">
        <el-row justify="space-between">
          <el-col :span="1" :lg="1" :xs="0" :sm="0"></el-col>
          <el-col class="left" :span="7" :lg="7" :xs="8" :sm="8">
            <el-row justify="start" align="top">
              <el-image class="left-img" :src="require('@/assets' + json.logo)"/>
            </el-row>
          </el-col>
          <!--
            class名
            pc端显示：hidden-xs-only
            移动端显示：hidden-sm-and-up
          -->
          <!-- <el-col :span="16" class="hidden-xs-only" v-if="json && json.menu"> -->
          <el-col :span="16" :lg="16" :xs="0" v-if="json && json.menu">
            <el-row justify="end" align="middle">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                :ellipsis="false"
                @select="handleSelect"
              >
                <!-- <div class="flex-grow" /> -->
                <el-menu-item v-for="(item, ind) in json.menu.list" :key="ind" :index="item.path" :route="item.path">{{item.name}}</el-menu-item>
              </el-menu>
            </el-row>

          </el-col>
          <!-- <el-col :span="10" class="hidden-sm-and-up" v-if="json && json.menu.list"> -->
          <el-col :xs="10" v-if="json && json.menu.list">
            <el-row justify="end" align="middle">
              <!-- <el-dropdown trigger="click" @command="handleCommand(command, abc)"> -->
              <el-dropdown trigger="click" @command="(command) => {
                  handleCommand(command, getindex);
                }">
                <!-- <div class="flex-grow" /> -->
                <span class="el-dropdown-link">
                  <el-icon :size="24" color="#409eff"><Operation /></el-icon>
                  <!-- Dropdown List<el-icon class="el-icon--right"><arrow-down /></el-icon> -->
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, index) in json.menu.list" :key="index" :command="composeValue(item, index)">{{item.name}}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
</template>

<script lang="ts">
// import { AnyColumn } from 'element-plus/es/components/table-v2/src/common'
// import 'element-plus/theme-chalk/display.css'
import { Options, Vue } from 'vue-class-component'
// import { ref } from 'vue'
// import { useStore, mapState } from 'vuex'
import { mapState } from 'vuex'
import { goUrl } from '@/utils/index'
import { useRouter } from 'vue-router'

@Options({
  // 注入父组件事件
  inject: ['reload'],
  data () {
    const router = useRouter()
    // console.log('router ===', router)
    const activeIndex = router.currentRoute.value && router.currentRoute.value.fullPath ? router.currentRoute.value.fullPath : '/'
    // console.log(router.currentRoute.value)
    // console.log('activeIndex ===', activeIndex)
    return {
      // activeIndex: '/'
      router: router,
      activeIndex: activeIndex
    }
  },
  computed: {
    ...mapState({
      language: (state:any) => state.language,
      json: (state:any) => state.jsons.header,
      imgUrl: (state:any) => state.imgUrl
    })
  },
  components: {
  },
  beforeCreate () {
    this.$store.dispatch('getAllJson').then((res:any) => {
      console.log('res =====', res)
    })
  },
  mounted () {
    // console.log(123)
    // console.log('useStore() ===', useStore())
    // console.log('json ===', this.json)
  },
  methods: {
    handleSelect (key: string, keyPath: string[], item:any) {
      const fullPath = this.router.currentRoute && this.router.currentRoute.fullPath ? this.router.currentRoute.fullPath : '/'
      // console.log('========= handleSelect =========')
      console.log(key, keyPath, item)
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      // console.log('this.router ===', this.router)
      // console.log('this.router.currentRoute ===', this.router.currentRoute)
      // console.log('fullPath ===', fullPath)
      // console.log('========= end =========')
      if (key !== this.activeIndex || fullPath !== this.activeIndex) {
        // console.log('========= goUrl =========')
        // console.log('========= key !== this.activeIndex =========')
        // console.log(key !== this.activeIndex)
        // console.log('========= fullPath !== this.activeIndex =========')
        // console.log(fullPath !== this.activeIndex)
        this.activeIndex = key
        goUrl(key)
      }
    },
    // 切换语言
    changeLanguage () {
      // console.log(this.language)
      // console.log(666)
      const str = this.language === 'cn' ? 'ar' : 'cn'
      this.$store.dispatch('changeJson', str).then(() => {
        this.reload()
      })
    },
    // 点击获取参数
    handleCommand (command:any) {
      // console.log('=== handleCommand ===')
      // console.log('command ===', command)
      const path = command.item.path
      // console.log('this.$store ===', this.$store)
      // console.log('this.$store.state ===', this.$store.state)
      // console.log('this.$store.state.header.avatar ===', this.$store.state.header.avatar)
      // console.log('this.$store.getters ===', this.$store.getters)
      // console.log('this.$store.getters.allState ===', this.$store.getters.allState())
      // 引用vuex方法
      // this.$store.commit('SET_TOKEN', 5)
      // this.$store.dispatch('setToken', 5)
      goUrl(path)
    },
    // 注入参数
    composeValue (item:any, index:any) {
      // console.log('=== composeValue ===')
      // console.log('it ===', it)
      // console.log('index ===', index)
      return {
        item: item,
        index: index
      }
    }
  }
})
export default class Header extends Vue {}
</script>
<style lang="scss" scoped>
@import '@/styles/index.scss';

  .el-row, .el-col{
    height: 100%;
  }
  .left-img{
    // max-height: 100%;
    // height: 80px;
    height: 100%;
  }
  .left button{
    height: 32px;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .el-dropdown-link{
    padding-right: 10px;
  }
  .layheader{
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow: hidden;
    .el-menu-demo{
      // height: 80px;
      height: 80%;
      border: 0;
      // background: $color-black;
      // margin-left: 40px;
      // .menu-li{
      //   font-size: 18px;
      //   padding: 0 30px;
      // }
      .el-menu-item{
        font-size: 18px;
        // padding: 0 30px;
        // color: #00796a;
        // color: #fff;
        border: 0;
        border-radius: 6px;
      }
      .el-menu-item:not(:last-child){
        margin-right: 10px;
      }
      // .menu-li:hover{
      //   background: #529b2e;
      //   color: #fff;
      // }
      .el-menu-item:hover,.el-menu-item:active{
        // background: #00796a !important;
        // color: #fff !important;
        // background: $color-white !important;
        // color: $color-black !important;
      }
      .el-menu-item.is-active{
        // background: #00796a !important;
        // color: #fff !important;
        // background: $color-white !important;
        // color: $color-black !important;
      }
    }
  }
</style>
