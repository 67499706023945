import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bb66570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "info-container" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = {
  key: 0,
  class: "info-content"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_LayFooter = _resolveComponent("LayFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.info_info && _ctx.info_info.logo)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          class: "info-banner",
          src: require('@/assets/imgs' + _ctx.info_info.logo)
        }, null, 8, ["src"]))
      : (_openBlock(), _createBlock(_component_el_image, {
          key: 1,
          class: "info-banner",
          src: require('@/assets/imgs' + _ctx.json.logo)
        }, null, 8, ["src"])),
    _createVNode(_component_el_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 16,
          xs: 22
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.info_info.name), 1),
              (_ctx.info_info.values)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info_info.values, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "info-module",
                        key: index
                      }, [
                        (item.type == 'h1' || 'h2' || 'h3' || 'p' || 'img' || 'img_assets')
                          ? (_openBlock(), _createBlock(_component_el_row, {
                              key: 0,
                              justify: item.justify || 'start',
                              align: item.align || 'top'
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, {
                                  span: item.span || 24,
                                  xs: 24
                                }, {
                                  default: _withCtx(() => [
                                    (item.type == 'h1')
                                      ? (_openBlock(), _createElementBlock("h1", {
                                          key: 0,
                                          class: _normalizeClass(item.class)
                                        }, _toDisplayString(item.value), 3))
                                      : _createCommentVNode("", true),
                                    (item.type == 'h2')
                                      ? (_openBlock(), _createElementBlock("h2", {
                                          key: 1,
                                          class: _normalizeClass(item.class)
                                        }, _toDisplayString(item.value), 3))
                                      : _createCommentVNode("", true),
                                    (item.type == 'h3')
                                      ? (_openBlock(), _createElementBlock("h3", {
                                          key: 2,
                                          class: _normalizeClass(item.class)
                                        }, _toDisplayString(item.value), 3))
                                      : _createCommentVNode("", true),
                                    (item.type == 'p')
                                      ? (_openBlock(), _createElementBlock("p", {
                                          key: 3,
                                          class: _normalizeClass(item.class)
                                        }, _toDisplayString(item.value), 3))
                                      : _createCommentVNode("", true),
                                    (item.type == 'img')
                                      ? (_openBlock(), _createBlock(_component_el_image, {
                                          key: 4,
                                          src: _ctx.imgUrl + item.value,
                                          class: _normalizeClass(item.class)
                                        }, null, 8, ["src", "class"]))
                                      : _createCommentVNode("", true),
                                    (item.type == 'img_assets')
                                      ? (_openBlock(), _createBlock(_component_el_image, {
                                          key: 5,
                                          src: require('@/assets' + item.value),
                                          class: _normalizeClass(item.class)
                                        }, null, 8, ["src", "class"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1032, ["span"])
                              ]),
                              _: 2
                            }, 1032, ["justify", "align"]))
                          : _createCommentVNode("", true),
                        (item.type == 'flex')
                          ? (_openBlock(), _createBlock(_component_el_row, {
                              key: 1,
                              class: _normalizeClass(["info-flex", item.class]),
                              justify: item.justify || 'start',
                              align: item.align || 'top'
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (data, key) => {
                                  return (_openBlock(), _createBlock(_component_el_col, {
                                    key: key,
                                    span: data.span || 10,
                                    xs: 24
                                  }, {
                                    default: _withCtx(() => [
                                      (data.type == 'p')
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(data.value), 1))
                                        : _createCommentVNode("", true),
                                      (data.type == 'img')
                                        ? (_openBlock(), _createBlock(_component_el_image, {
                                            key: 1,
                                            src: _ctx.imgUrl + data.value
                                          }, null, 8, ["src"]))
                                        : _createCommentVNode("", true),
                                      (data.type == 'img_assets')
                                        ? (_openBlock(), _createBlock(_component_el_image, {
                                            key: 2,
                                            src: require('@/assets' + data.value),
                                            class: _normalizeClass(item.class)
                                          }, null, 8, ["src", "class"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["span"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["justify", "align", "class"]))
                          : _createCommentVNode("", true),
                        (item.type == 'br')
                          ? (_openBlock(), _createElementBlock("br", _hoisted_6))
                          : _createCommentVNode("", true),
                        (item.type == 'divider')
                          ? (_openBlock(), _createBlock(_component_el_divider, { key: 3 }))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_LayFooter)
  ]))
}