const state = {
  token: '',
  name: 'header name',
  avatar: 'string'
}
const mutations = {
  // SET_TOKEN: (state:any, text:any) => {
  SET_TOKEN: () => {
    // state.token = text
    console.log('SET_TOKEN')
  }
}
const actions = {
  // setToken ({ commit }:any, text:any) {
  setToken () {
    console.log('setToken')
  }
}

export default {
  state,
  mutations,
  actions
}
