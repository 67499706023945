<template>
  <div>
    <div v-for="(aItem, ind) in json.list" :key="ind">
      <template v-if="aItem.type === 'banner' && aItem.list_type === 'banner'">
        <el-carousel
          :autoplay="false"
          height="5rem"
          class="banner-carousel"
        >
          <el-carousel-item v-for="(item, index) in aItem.list" :key="index">
              <!-- <el-image class="full-img" fit='scale-down' :src="imgUrl + item.img" @dragstart.prevent/> -->
                <el-image class="full-img" fit='cover' :src="require('@/assets' + item.img)" @dragstart.prevent/>
          </el-carousel-item>
        </el-carousel>
      </template>
      <template v-if="aItem.type === 'img'">
        <pageWrap>
          <el-image class="full-img" fit='contain' :src="require('@/assets' + aItem.img)" @dragstart.prevent/>
        </pageWrap>
      </template>

      <!-- 居中列表 -->
      <!-- <template v-if="aItem.type === 'list' && aItem.list_type === 'module_img'">
        <pageWrap class="has_150_pad_bottom">
          <div class="text-center">
            <div class="size-28 has_pad_bottom has_pad_top">
              {{aItem.title}}
            </div>
            <div class="size-16">
              {{aItem.subtitle}}
            </div>
          </div>
          <el-row justify="space-evenly" class="container-module">
            <el-col :span="5" :xs="7"
              v-for="(item, index) in aItem.list" :key="index"
              class="img_card_module"
              @click="toLink(item.path)"
            >
              <el-card
                class="module_content"
                :body-style="{ padding: '10px' }"
              >
                <div class="module_img_wrap can_bigger">
                  <el-image class="module_img" fit='scale-down' :src="require('@/assets' + item.img)" @dragstart.prevent/>
                </div>
                <div class="module_bottom flex f-align-center f-justify-center">
                  {{item.name}}
                </div>
              </el-card>
            </el-col>
          </el-row>
        </pageWrap>
      </template> -->
      <template v-if="aItem.type === 'list' && aItem.list_type === 'img_name' && false">
        <pageWrap class="has_100_pad_bottom">
          <div class="text-center">
            <div class="size-28 has_pad_bottom has_pad_top">
              {{aItem.title}}
            </div>
            <div class="size-16">
              {{aItem.subtitle}}
            </div>
          </div>
          <el-row justify="space-evenly" class="container-module">
            <el-col :span="8" :xs="7"
              v-for="(item, index) in aItem.list" :key="index"
              class="img_card_module"
              @click="toLink(item.path)"
            >
              <el-card
                class="module_content"
                :body-style="{ padding: '10px' }"
              >
                <div class="module_img_wrap">
                  <el-image class="module_img" fit='scale-down' :src="require('@/assets' + item.img)" @dragstart.prevent/>
                </div>
                <div class="module_bottom flex f-align-center f-justify-center">
                  {{item.name}}
                </div>
              </el-card>
            </el-col>
          </el-row>
        </pageWrap>
      </template>

      <template v-if="aItem.type === 'text' && aItem.list_type === 'introduce'">
        <pageWrap class="has_100_pad_bottom">
          <div class="introduce-container">
            <el-row class="introduce-content size-18" justify="center">
              <el-col :span="12" :xs="24">
                <h3 class="no-margin-top">{{aItem.title}}</h3>
                <template v-for="(item, index) in aItem.values" :key="index">
                  <p v-if="item.type == 'text'" :class="item.class">{{item.text}}</p>
                </template>
              </el-col>
              <el-col :span="1" :xs="0">
              </el-col>
              <el-col :span="11" :xs="24">
                <el-image fit='scale-down' :src="require('@/assets/imgs/introduce.jpg')"/>
              </el-col>
            </el-row>
          </div>
        </pageWrap>
      </template>

      <template v-if="aItem.type === 'list' && aItem.list_type === 'img' && false">
        <pageWrap class="has_100_pad_bottom">
          <div class="text-center">
            <div class="size-28 has_pad_bottom has_pad_top">
              {{aItem.title}}
            </div>
            <div class="size-16">
              {{aItem.subtitle}}
            </div>
          </div>
          <el-row justify="space-evenly" class="container-module">
            <el-col :span="8"
              v-for="(item, index) in aItem.list" :key="index"
              class="img_card_module"
              @click="toLink(item.path)"
            >
              <el-card
                class="module_content"
                :body-style="{ padding: '10px' }"
              >
                <div class="module_img_wrap">
                  <el-image class="module_img" fit='scale-down' :src="require('@/assets' + item.img)" @dragstart.prevent/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </pageWrap>
      </template>
    </div>
    <div>
      <!-- <el-row justify="center" class="has_pad_top size-28 color-white"> -->
      <el-row justify="center" class="has_pad_top size-28">
        公司地址
      </el-row>
      <el-row justify="center" class="about-wrap has_150_pad_bottom">
        <div id="homeMap" style="width: 800px;height: 400px;max-width: 90%;" @mousedown.stop></div>
      </el-row>
      <div class="home_footer" @mousedown.stop>
        <LayFooter/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import LayFooter from '@/components/layout/layfooter.vue'
import pageWrap from '@/components/pageWrap.vue'
import { goUrl } from '@/utils/index'
let BMap:any = {}

@Options({
  data () {
    return {
      banners: [],
      carousel_y: 0,
      // 位移距离 需超过该位移距离才有变化
      carousel_distance: 20,
      no_drag: false,
      mouse_down: false
    }
  },
  components: {
    LayFooter,
    pageWrap
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.home,
      language: (state:any) => state.language
    }),
    carouselHeight () {
      console.log(document.documentElement.clientHeight)
      // return document.documentElement.clientHeight - 100
      return 300
    }
  },
  mounted () {
    console.log('this.json ===', this.json)
    this.createMap()
  },
  methods: {
    createMap () {
      // console.log(window)
      BMap = (window as any).BMapGL
      // console.log('BMap ====', BMap)
      console.log('json ====', this.json)
      this.$nextTick(() => {
        const mapInfo = this.json.map
        const map = new BMap.Map('homeMap')
        const point = new BMap.Point(mapInfo.coordinateX, mapInfo.coordinateY)
        map.centerAndZoom(point, mapInfo.level)
        map.enableScrollWheelZoom(true)
        const marker = new BMap.Marker(point)
        marker.setTitle('郑州德原电力')
        map.addOverlay(marker)
      })
    },
    // 鼠标滑轮滚动
    mousewheel (e:any) {
      // console.log('mousedown e ===', e)
      const scrollVal = e.wheelDelta || e.detail
      scrollVal > 0 ? this.$refs.elCarousel.prev() : this.$refs.elCarousel.next()
    },
    mousedown (e:any) {
      // console.log('mousedown e ===', e)
      // console.log('mousedown e.target.className ===', e.target.className)
      this.mouse_down = true
      this.carousel_y = e.y
    },
    mouseup (e:any) {
      // console.log('mouseup e ===', e)
      if (this.mouse_down) {
        this.mouse_down = false
        if (e.y > this.carousel_y + this.carousel_distance) {
          this.$refs.elCarousel.prev()
        } else if (e.y < this.carousel_y - this.carousel_distance) {
          this.$refs.elCarousel.next()
        }
      }
    },
    toLink (path:any) {
      console.log(path)
      if (path && path !== '/') {
        goUrl(path)
      }
    }
  }
})
export default class Home extends Vue {}
</script>
<style lang="scss" scoped>
.home{
  height: 100%;
}
.kong{
  width: 100%;
  height: 40px;
}

.el-carousel{
  height: 100%;
}

.banner-carousel{
  // @media (min-width: 768px) {
  //   padding: 50px 0 0;
  // }
}

.full-img{
  width: 100%;
  height: 100%;
}

.has_pad_top{
  padding-top: 10px;
}
.has_pad_bottom{
  padding-bottom: 10px;
}

.has_100_pad_bottom{
  padding-bottom: 100px;
}

.has_150_pad_bottom{
  padding-bottom: 150px;
}

.no-margin-top{
  margin-top: 0;
}

.container-module{
  padding: 30px 0 0;
}

.img_card_module{
  margin-bottom: 20px;
  .module_content{
    width: 100%;
    .module_img_wrap{
      width: 100%;
      overflow: hidden;
      .module_img{
        width: 100%;
        padding: 10px;
        // height: 160px;
      }
    }
    .can_bigger{
      .module_img{
        animation: img_smaller 0.2s 1 linear forwards;
      }
      .module_img:hover {
        animation: img_bigger 0.2s 1 linear forwards;
      }
    }
    .module_bottom{
      width: 100%;
      height: 40px;
    }
  }
}
@keyframes img_smaller {
  0%{
    transform: scale(1.5,1.5);
  }
  100%{
    transform: scale(1,1);
  }
}
@keyframes img_bigger {
  0%{
    transform: scale(1,1);
  }
  100%{
    transform: scale(1.5,1.5);
  }
}

.home_footer{
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  width: 100%;
}

.about-wrap{
  padding-top: 40px;
}

.introduce-content{
  p {
    text-indent: 36px;
  }
}

</style>
