import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d2272d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-wrap" }
const _hoisted_2 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LayFooter = _resolveComponent("LayFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.list_obj && _ctx.list_obj.logo)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          class: "list-banner",
          src: require('@/assets/imgs' + _ctx.list_obj.logo)
        }, null, 8, ["src"]))
      : (_openBlock(), _createBlock(_component_el_image, {
          key: 1,
          class: "list-banner",
          src: require('@/assets/imgs' + _ctx.json.logo)
        }, null, 8, ["src"])),
    _createVNode(_component_el_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 16,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { class: "list-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { class: "list-content" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists_arr, (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_col, {
                            span: 8,
                            xs: 11,
                            key: index,
                            class: "list-li",
                            onClick: ($event: any) => (_ctx.goInfo(item))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_image, {
                                    src: item.coverImage.indexOf('http') >= 0 ? item.coverImage: require('@/assets/imgs' + item.coverImage)
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_el_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                                  }, _toDisplayString(item.name), 9, _hoisted_2)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    (_ctx.lists_arr && _ctx.lists_arr.length > 0)
                      ? (_openBlock(), _createBlock(_component_el_row, {
                          key: 0,
                          justify: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_pagination, {
                              class: "info-pagination",
                              small: "",
                              background: false,
                              layout: "prev, pager, next",
                              "page-size": _ctx.page_size,
                              total: _ctx.total * 1,
                              "current-page": _ctx.activePage,
                              onCurrentChange: _ctx.handleCurrentChange
                            }, null, 8, ["page-size", "total", "current-page", "onCurrentChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_LayFooter)
  ]))
}