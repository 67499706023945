import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-660b36b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rightnav-wrap" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Top = _resolveComponent("Top")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!
  const _component_Phone = _resolveComponent("Phone")!
  const _component_Message = _resolveComponent("Message")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.list, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_row, {
        class: "nav-module",
        align: "middle",
        justify: "center",
        key: index
      }, {
        default: _withCtx(() => [
          (item.type == 'top')
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                class: "nav-btn",
                type: "primary",
                onClick: _ctx.toTop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Top)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (item.type == 'pop')
            ? (_openBlock(), _createBlock(_component_el_popover, {
                key: 1,
                placement: "left",
                title: item.name,
                width: 200,
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: "nav-btn",
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      (item.icon == 'ChatDotRound')
                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ChatDotRound)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (item.icon == 'Phone')
                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Phone)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (item.icon == 'Message')
                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 2 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Message)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (item.value_type == 'img')
                      ? (_openBlock(), _createBlock(_component_el_image, {
                          key: 0,
                          src: require('@/assets' + item.value)
                        }, null, 8, ["src"]))
                      : _createCommentVNode("", true),
                    (item.value_type == 'text')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.copy(item.value))
                        }, _toDisplayString(item.value), 9, _hoisted_2))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["title"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}